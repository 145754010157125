<template>
  <div class="uploader-2">
    <el-card class="video-comp">
      <el-form size="small" label-width="120px">

        <el-form-item label="视频">
            <!-- :http-request="onUpload" -->
          <el-upload
            :action="action"
            :multiple="false"
            :show-file-list="false"
            :before-upload="onReadFile"
            accept="video/mp4">
            <el-button v-if="!loading" size="small" type="primary" slot="trigger">点击上传</el-button>
            <el-button v-else size="small" type="primary" slot="trigger"
                       icon="el-icon-loading">正在上传
            </el-button>
            <el-button-group>
              <el-button size="small" @click="() => onCreate('直播')">创建直播</el-button>
              <el-button size="small" @click="() => onCreate('腾讯视频')">插入腾讯视频</el-button>
            </el-button-group>
            <el-progress v-if="percentage !== 0" :stroke-width="2" :percentage="percentage"/>
          </el-upload>
        </el-form-item>

        <div v-if="vid">
          <el-form-item label="VID">
            {{ `video-${vid}`}}
          </el-form-item>
          <el-form-item label="视频类型">
            <el-select v-model="video.type" placeholder="选择类型">
              <el-option v-for="c in ['视频', '腾讯视频', '直播'] " :label="c" :value="c" :key="c"/>
            </el-select>
          </el-form-item>
          <el-form-item label="视频标题">
            <el-input v-model="video.title"/>
          </el-form-item>
          <div v-if="video.type === '直播'">
            <el-form-item label="直播房间号">
              <el-input v-model="video.live_room_id"/>
            </el-form-item>
          </div>

          <div v-if="video.type === '腾讯视频'">
            <el-form-item label="腾讯视频ID">
              <el-input v-model="video.txv_id"/>
            </el-form-item>
          </div>

          <div v-if="video.type === '视频'">
            <el-form-item label="视频连接">
              <ul>
                <li><a :href="video.video_full">清晰度 - 原画 - 未转码</a></li>
                <li v-if="video.video_hd"><a :href="video.video_hd">清晰度 - 高清 - 720p</a></li>
                <li v-if="video.video_sd"><a :href="video.video_sd">清晰度 - 原画 - 480p </a></li>
              </ul>
              <div slot="tip" class="el-upload__tip">上传的视频大于 720p 分辨率时，会自动转码，并分为三个清晰度：原画、高清、标清</div>
            </el-form-item>
            <el-form-item label="播放参数">
              <el-checkbox v-model="video.is_vertical">垂直显示模式</el-checkbox>
            </el-form-item>
            <el-form-item label="视频状态">
              {{ video.status }}
            </el-form-item>
            <el-form-item label="视频封面">
              <input-image-single v-model="video.cover"/>
            </el-form-item>
          </div>
          <el-form-item>
            <div style="text-align: right">
              <el-button type="primary" @click="onRemove">删除视频</el-button>
              <el-button type="primary" @click="onSubmit">提交视频信息</el-button>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  return format.replace(/{([ymdhisa])+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
}
  var client = {}
  import settings from '../settings'

  import OSS from 'ali-oss'
  const azblob = require('../plugins/azure-storage-blob.js')

  export default {
    props: ['value', 'update', 'remove'],
    model: {
      prop: 'value',
      event: 'change',
    },
    computed: {},
    data() {
      return {
        action: settings.urls.uploadVideo,
        files: [],
        vid: '',
        video: {},
        showPreviewVideo: false,
        ossFileURL: 'https://ccago.oss-cn-hangzhou.aliyuncs.com/',
        previewVideoUrl: '',
        percentage: 0,
        loading: false,
        ossAccessTokenURL: 'https://api.ccachina.net/core/attachment/aliOssSts',
      }
    },
    mounted() {
    },
    watch: {
      value(v) {
        console.log('video comp v change ', v)
        if (v) {
          this.vid = v
          this.load()
        } else {
          this.video = {}
          this.files = []
        }
      }
    },
    methods: {

      onReadFile: function (file, evt) {
        console.log('------uploadFile------')
        console.log('file', file)
        console.log('evt', evt)
        let that = this
        that.showLoading = true
        OSS.urllib.request(
          that.ossAccessTokenURL,
          { method: 'GET' },
          function (err, response) {
            let result
            if (err) {
              alert('上传异常:' + err)
              console && console.error(err)
              return
            }

            try {
              result = JSON.parse(response)
            } catch (e) {
              return alert('parse sts response info error: ' + e.message)
            }

            // that.ossFileURL = result.data.AliOssUrl
            client = new OSS({
              accessKeyId: result.data.AccessKeyId,
              accessKeySecret: result.data.AccessKeySecret,
              stsToken: result.data.SecurityToken,
              endpoint: result.data.endpoint,
              bucket: result.data.bucket,
              secure: true,
            })

            // alert(typeof  evt);
            // return ;
            //单选
            let suffix = file.name.toLowerCase().split('.').splice(-1)[0]

            console.log('file')
            console.log(file.size)
            // if (file.size > 1000 * 1000 * 16) {
            // Toast.fail(file.name + '图片不能超过16M')
            // that.showLoading = false
            // return
            // }

            var date = parseTime(new Date().getTime(), '{y}{m}{d}')
            var time_str = parseTime(new Date().getTime(), '{h}{i}{s}')
            // console.log('time_str',time_str);
            let time = time_str + '-' + Math.floor(Math.random() * 1000 + 1)
            // let date = that.dateFormat(myDate);
            var file_save_name =
              '' + date + '/' + that.userid + '/' + time + '.' + suffix

            // that.list.unshift({
            //   uid: 'vc-upload-' + time,
            //   url: '',
            //   name: file.name,
            //   save_name: file_save_name,
            //   loading: true,
            //   progress: 0,
            // })

            client
              .multipartUpload(file_save_name, file, {
                progress: function (p, checkpoint) {
                  console.log('----上传进度----', checkpoint)
                  that.percentage = parseInt(p * 100)
                  // console.log(that.progress)
                  // console.log(p)

                  // that.list.map((item) => {
                  //   if (item.uid == 'vc-upload-' + time) {
                  //     console.log(parseInt(p * 100))
                  //     item.progress = parseInt(p * 100)
                  //   }
                  // })

                  // that.$emit('uploadProgress', {
                  //   progress: parseInt(p * 100),
                  //   name: file.name,
                  // })
                  //                 console.log(p,checkpoint)
                },
              })
              .then(function (result) {
                console.log(result)
                console.log(that.ossFileURL)
                // var rfile = {
                //   uid: 'vc-upload-' + time,
                //   url: that.ossFileURL + '/' + file_save_name,
                //   name: file.name,
                //   save_name: file_save_name,
                // }

                // that.list.map((item) => {
                //   if (item.uid == 'vc-upload-' + time) {
                //     item.loading = false
                //     item.url = that.ossFileURL + '/' + file_save_name
                //   }
                // })

                // that.showLoading = false
                // Toast.success(file.name + '上传成功');
                // $name,$uid,$url,$save_name,$suffix,$filetype,$size;

                // let attachmentData = {
                //   name: rfile.name,
                //   uid: rfile.uid,
                //   url: rfile.url,
                //   save_name: rfile.save_name,
                //   suffix: suffix,
                //   filetype: 2,
                //   size: file.size,
                // }
                // that.attachmentAdd(attachmentData)
                // that.$emit('uploadSuccess', attachmentData)

                that.onCreate('视频', that.ossFileURL + '/' + file_save_name)
              })
              .catch(function (err) {
                console.log('error', err)
                // Toast.fail(file.name + '上传失败');
              })
          }
        )
        return false // false就是不自动上传，我后来试了发现都一样，都不会自动上传
      },
      async onUpload({file}) {
        this.loading = true
        const containerURL = new azblob.ContainerURL(
          'https://p3com.blob.core.chinacloudapi.cn/wb-llc' + this.$settings.az_blob_sas,
          azblob.StorageURL.newPipeline(new azblob.AnonymousCredential))

        const filename = Math.random().toString(36).slice(-10) + '.mp4'
        try {
          const blockBlobURL = azblob.BlockBlobURL.fromContainerURL(containerURL, filename)
          console.log('filename', filename, blockBlobURL)
          await azblob.uploadBrowserDataToBlockBlob(
            azblob.Aborter.none, file, blockBlobURL, {
              progress: (e) => {
                this.percentage = e.loadedBytes / file.size * 100 | 0
              }
            })
        } catch (error) {
          this.$message.error('上传失败' + error.body.message)
          this.loading = false
          return
        }
        this.loading = false
        this.onCreate('视频', filename)
      },
      load() {
        this.$api.request('/video_get', {id: this.vid}).then(data => {
          this.video = data
          this.files = [{url: data.video_full, name: '视频'}]
        })
      },
      onCreate(type, filename = null) {
        this.loading = true
        this.$api.request('/video_create', {type, blob_name: filename}).then(data => {
          this.loading = false
          this.$message.success('视频创建成功！')
          this.$emit('change', data.id)
          this.vid = data.id
          this.video = data

          if (this.update) {
            this.update(data)
          }
        })
      },
      onSubmit() {
        this.$api.request('/video_update', this.video).then(data => {
          this.video = data
          if (this.update) {
            this.update(data)
          }
        })
      },
      onRemove() {
        if (confirm(`确定移除？`)) {
          this.vid = ''
          this.video = {}
          this.files = []
          this.$emit('change', null)

          if (this.remove) {
            this.remove(this.video)
          }

        }
      },
      openPreview() {
        window.open(this.video.video_full)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .video-comp {
    width: 550px;

    .el-upload {
      text-align: left !important;

    }
  }

</style>
