<template>
  <el-input
    class="table-search-input"
    v-model="key"
    ref="saveTagInput"
    placeholder="搜索 ..."
    suffix-icon="el-icon-search"
    clearable
    :size="size"

    @input="onSearch"
    @keyup.enter.native="onSearch">
  </el-input>

</template>

<script>
  import Fuse from 'fuse.js'

  export default {
    props: {
      value: {
        type: Array,
        default: [],
      },
      fields: {
        type: Array,
        default: [],
      },
      load: {
        type: Function,
      },
      tableData: {
        type: Array,
        default: [],

      },
      size: {
        type: String,
      }
    },
    directives: {},
    model: {
      prop: 'value',
      event: 'change'
    },
    data() {
      return {
        key: '',
      }
    },
    methods: {
      onChange() {
        if (!this.key) {
          return this.load()
        }
      },
      onSearch() {
        let res
        if (this.key) {
          let fuse = new Fuse(this.tableData, {keys: this.fields})
          res = fuse.search(this.key)
        } else {
          res = this.tableData
        }
        this.$emit('change', res.map(r => r.item))
      }

    }
  }
</script>

<style lang="scss">
  .table-search-input {
    width: 260px;
  }
</style>
