import { storage } from "./utils/storage"


let DataStore = {}



DataStore.install = function (Vue, options = {}) {

  let store = storage.get('store', {})

  let _global_tmp = {
    ts: 0,
    ...options.initData,
    account: store.account || {},
    config: store.config || {},
  }

  let _global_ = new Proxy(_global_tmp, {
    set: function (receiver, property, value) {
      receiver[property] = value;
      // console.log('CHANGE VAR', property, value)
      storage.save('store', receiver)
      return true
    }
  })

  new Vue({
    data() {
      return {
        $$state: {
          _global_
        }
      }
    },
    watch: {
      _global_(v) {
      }
    }
  });

  Vue.prototype.$dbus = _global_

}


export {
  DataStore
}

// let DataStore = {}
//
// let _global_tmp = {}
//
// DataStore.install = function (Vue, options = {}) {
//
//   _global_tmp = {
//     ts: 0,
//     ...(options.initData || {})
//   }
//
//
//   var interceptor = {
//     set: function (receiver, property, value) {
//       // todo 这里每次变化的时候应该存 session
//       receiver[property] = value;
//       // storage.save('data', receiver)
//       return true
//     }
//   };
//
//   let _global_ = new Proxy(_global_tmp, interceptor)
//
//   new Vue({
//     data() {
//       return {
//         $$state: {
//           _global_
//         }
//       }
//     },
//     watch: {
//       _global_(v) {
//         console.log('CHANGE V', v)
//       }
//     }
//   });
//
//   Vue.prototype.$dbus = _global_
//   Vue.prototype.$settings = (options.settings || {})
//
// }
//
//
// export {
//   DataStore
// }
