<template>
  <div class="app-navbar">

    <div class="hamburger-wrapper">
      <svg class="hamburger"
           height="64"
           viewBox="0 0 1024 1024"
           width="64" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z"/>
      </svg>
    </div>

    <breadcrumb/>
    <div class="grow"></div>
    <div class="right-menu">
      <search v-if="$settings.features.searchGlobal" id="header-search" class="item"/>

      <el-badge :hidden="msg_unread === 0" :value="msg_unread" class="item">
        <i class="el-icon-bell" style="font-size: 15px" @click="toggleNoticesBar"></i>
      </el-badge>

      <el-dropdown class="item" @command="handleCommand">
        <span class="el-dropdown-link">
          {{ $dbus.account.nickname || '未登录' }} <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!--          <el-dropdown-item command="settings">系统设置</el-dropdown-item>-->
          <el-dropdown-item command="logout" divided>退出登陆</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-drawer :visible.sync="showNoticesBar"
               class="notices-bar"
               direction="rtl"
               title="通知消息">
      <div class="content">
        <div v-for="n in msg_contains" :key="n.id" class="notice-item" @click="() => openMessage(n)">
          <div class="_header">
            <el-tag size="mini" type="danger">未读</el-tag>
            {{n.title}}
          </div>

          <div class="_content">
            {{ n.content }}
          </div>
          <div class="time">
            <!--            来自<span class="user">{{ n.from_user.nickname}}</span>的消息-->
            <span class="time">{{ n.datetime_created }}</span>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Breadcrumb from '../../components/Breadcrumb'
import Search from '../../components/global-header-search'
import {requestSilent} from "../../utils/request"
// import api from '@/core/api'

export default {
  components: {
    Breadcrumb,
    // Hamburger,
    Search,
  },
  computed: {},
  data() {
    return {
      msg_unread: 0,
      msg_contains: [],
      showNoticesBar: false,
      sidebar: {
        opened: true,
      },
      notices: [],
    }
  },
  mounted() {
    // console.log(this.$settings.features.enableNotice)
    if (this.$settings.features.enableNotice) {
      setInterval(() => {
        this.load()
      }, 5 * 1000)
    }

  },
  methods: {
    load() {
      requestSilent('/user/get_unread_msg').then(data => {
        this.msg_unread = data.unread
        this.msg_contains = data.contains
      })
    },
    openMessage(n) {
      this.$api.request('/user/set_msg_status', {id: n.id}, {silent: true}).then(data => {
        this.load()
        this.toggleNoticesBar()
        this.$router.push(n.url)
      })
    },
    handleCommand(c) {
      if (c === 'settings') {
        // this.$router.push({name: 'settings'})
      } else if (c === 'logout') {
        this.$api.account.logout()
        this.$router.push({name: 'login'})
      }
    },
    toggleSideBar() {
      // this.$store.dispatch('app/toggleSideBar')
    },
    toggleNoticesBar() {
      this.showNoticesBar = !this.showNoticesBar
    }
  }
}
</script>

<style lang="scss" scoped>

.notices-bar {
  .notice-item {
    &:hover {
      border: 1px solid #409EFF;
      /*box-shadow: 0 2px 8px 0 #409EFF;*/

    }

    border: 1px solid #EBEEF5;
    background-color: #FFF;
    border-radius: 3px;
    color: #303133;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
    padding: 10px 18px;
    margin: 10px 15px;
    font-size: 15px;

    .title {
      color: #5a6268;
      font-size: 20px;
    }

    .time {
      font-size: 12px;
      color: #aaa;
      margin-top: 8px;
    }

    ._content {
      margin-top: 7px;
      font-size: 13px;
      color: #787878;
      max-height: 38px;
      line-height: 18px;

      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      /*! autoprefixer: off */
      -webkit-box-orient: vertical;

    }

  }
}


.hamburger-wrapper {
  width: 40px;
  text-align: center;
}

.hamburger {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.hamburger.is-active {
  transform: rotate(180deg);
}

</style>

