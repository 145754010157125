<template>
  <span class="tags">
<el-tag :key="tag" v-for="tag in value"
        closable
        :disable-transitions="false"
        @close="handleClose(tag)">
  {{tag}}
</el-tag>
<el-input
  class="input-new-tag"
  v-if="inputVisible"
  v-model="inputValue"
  ref="saveTagInput"
  size="small"
  @keyup.enter.native="handleInputConfirm"
  @blur="handleInputConfirm"
>
</el-input>
<el-button v-else class="button-new-tag" size="small" @click="showInput"> + 新增 </el-button>

  </span>
</template>

<script>
  export default {
    props: {
      value: Array
    },
    model: {
      prop: 'value',
      event: 'change'
    },

    data() {
      return {
        inputVisible: false,
        inputValue: ''
      }
    },
    mounted() {
      if (!this.value) {
        this.$emit('change', [])
      }
    },
    computed: {},
    methods: {
      handleClose(tag) {
        let value = this.value
        value.splice(this.value.indexOf(tag), 1)
        this.$emit('change', value)
      },

      showInput() {
        this.inputVisible = true
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus()
        })
      },
      handleInputConfirm() {
        let value = this.value || []
        let inputValue = this.inputValue
        if (inputValue) {
          value.push(inputValue)
        }
        this.inputVisible = false
        this.inputValue = ''
        this.$emit('change', value)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .el-tag + .el-tag {
    margin-left: 10px;
  }

  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
</style>

