import Clipboard from "clipboard"

/***
 * 辅助函数
 *
 * @param path
 */

export const router = {
  redirect(path) {

  }
}

export const clipboard = {
  copy(text, event) {
    const clipboard = new Clipboard(event.target, {
      text: () => text
    })
    clipboard.on('success', () => {
      Vue.prototype.$message.success('复制成功：' + text)
      clipboard.off('error')
      clipboard.off('success')
      clipboard.destroy()
    })
    clipboard.on('error', () => {
      Vue.prototype.$message.error('复制成功：' + text)
      clipboard.off('error')
      clipboard.off('success')
      clipboard.destroy()
    })
    clipboard.onClick(event)
  }
}
