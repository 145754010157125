import axios from 'axios'
import {Message, MessageBox} from 'element-ui'
import Cookies from 'js-cookie'
import settings from '@/settings'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


export function requestSilent(url, data) {
  return new Promise((resolve, reject) => {

    axios.request({
      method: 'post',
      url: settings.host + url,
      headers: {
        [settings.headerToken]: Cookies.get(settings.headerToken, null),
        'Content-Type': 'application/json'
      },
      data: data || {},
    }).then(({data, status}) => {

      if (status === 401) {
        MessageBox.alert('抱歉，您尚未登陆', '前往登陆', {
          type: 'error'
        }).then(() => {
          // todo 重定向到登陆页面
          return 1
        })
      }

      return resolve(data)
    }).catch(({message, response}) => {
      return reject({})
    })
  })

}


export function request(url, data, options = {}) {
  console.log('req data: ', data)

  return new Promise((resolve, reject) => {
    // let loading = Message({
    //   message: '请求中 ...',
    //   type: 'info',
    //   duration: 0,
    //   iconClass: 'el-icon-loading',
    // })
    if (options.silent === true) {
    } else {
      NProgress.start();
    }
    let host  = settings.host
    if(options.host){
      host = options.host
    }

    axios.request({
      method: 'post',
      url: host + url,
      headers: {
        [settings.headerToken]: Cookies.get(settings.headerToken, null),
        'Content-Type': 'application/json'
      },
      data: data || {},
    }).then(({data, status}) => {
      NProgress.done()

      if (status === 401) {
        MessageBox.alert('抱歉，您尚未登陆', '前往登陆', {
          type: 'error'
        }).then(() => {
          // todo 重定向到登陆页面
          return 1
        })
      }

      if (data.error) {
        return Message.error(data.error)
      }

      return resolve(data)
    }).catch(({message, response}) => {
      NProgress.done()
      console.log(({message, response}))

      if (!response) {
        Message.error('当前网络错误，请检查您的网络')

        return reject({})

      }
      // console.log('error', response.data, response.status, message)
      Message.error(response.data.error || message)
      // Message({
      //   message: response.data.error || message,
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      return reject(response.data)
    })
  })
}

// // create an axios instance
// const service = axios.create({
//   baseURL: process.env.base, // api 的 base_url
//   timeout: 5000 // request timeout
// })
//
// // request interceptor
// service.interceptors.request.use(
//   config => {
//     config.headers['Content-Type'] = 'application/json'
//     config.headers[settings.headerToken] = Cookies.get(settings.headerToken, null)
//     return config
//   },
//   error => {
//     // Do something with request error
//     console.log('error !!', error) // for debug
//     Promise.reject(error)
//   }
// )
//
// // response interceptor
// service.interceptors.response.use(
//   // response => response,
//   /**
//    * 下面的注释为通过在response里，自定义code来标示请求状态
//    * 当code返回如下情况则说明权限有问题，登出并返回到登录页
//    * 如想通过 xmlhttprequest 来状态码标识 逻辑可写在下面error中
//    * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
//    */
//   ({data, status}) => {
//     if (status === 401) {
//       // 请自行在引入 MessageBox
//       MessageBox.alert('你尚未登陆', '前往登陆', {
//         type: 'error'
//       }).then(() => {
//         // todo 重定向到登陆页面
//       })
//     }
//
//     if (data.error) {
//       Message({
//         message: data.error,
//         type: 'error',
//         duration: 5 * 1000
//       })
//       // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
//
//       return Promise.reject('error')
//     } else {
//       return data
//     }
//   },
//   ({message, response}) => {
//     // console.log('error', response.data, response.status, message)
//     Message({
//       message: response.data.error || message,
//       type: 'error',
//       duration: 5 * 1000
//     })
//     return Promise.reject(error)
//   }
// )
//
// export default function request(path, data = {}) {
//   return service({
//     url: settings.urls.base + path,
//     method: 'post',
//     headers: {
//       [settings.headerToken]: Cookies.get(settings.headerToken, null),
//       'Content-Type': 'application/json'
//     },
//     data
//   })
// }


