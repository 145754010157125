const DEBUG = false
// const DEBUG = false
// const DEBUG = process.env.NODE_ENV === 'development'
// const BASE_HOST = DEBUG? 'http://127.0.0.1:8008':'https://llc.hosts.pnhex.com'
const BASE_HOST = DEBUG? 'http://127.0.0.1:8015':'https://api-llzx.ccachina.net'
window.host2 = 'https://api2-llzx.ccachina.net'
// window.host2 = 'http://api.llzx.net'

module.exports = {
  version: '0.0.0',
  debug: DEBUG,
  host: BASE_HOST,
  urls: {
    host: BASE_HOST,
    base: BASE_HOST,
    upload: 'https://api.ccachina.net/core/attachment/llzxUploadFile',//BASE_HOST + '/upload',
    uploadVideo: 'http://llc.hosts.pnhex.com:8029/video_create_from_upload'
    // uploadVideo: BASE_HOST + '/video_create_from_upload'
  },

  title: '啦啦之星 管理后台',
  description: '',
  permission: {
    page: {
      admin: 'all',
      user: ['some-page',]
    },
    action: {}
  },
  features: {
    searchGlobal: false,
    needLogin: !DEBUG,
    themeSwitch: false,
    logger: false,
    fixedHeader: false,
    showSidebarLogo: true,
    showSidebarVersion: false,
    enabledMock: false,  // import './mock',
    forcedLogin: true,
  },
  errorLog: 'production',
  headerToken: 'Authorization',
  defaultUISize: 'medium',
  initData: {},
  theme: 'default',
  autoRoutes: true,  // todo 自动配置路由
  layout: 'vertical',  // vertical 左右  horizontal 上下
  home: '/dashboard', // 首页 / 指向
  enums_data: {
    post_types: ['帖子分类A', '帖子分类B', '帖子分类C', '帖子分类D', '帖子分类E'],
    course_types: ['街舞', '爵⼠', '花球', '技巧', '彩妆', '访谈'],
    goods_types: ['商品分类A', '商品分类B', '商品分类V', '商品分类C', '商品分类AD'],
    user_types_1: ['学生', '老师', '教练'],
    user_types_2: ['技巧', '花球', '街舞', '爵士']
  },
  admin_password: '123456q',
  az_blob_sas: '?sv=2019-10-10&ss=b&srt=sco&sp=rwdlac&se=2021-11-30T16:36:44Z&st=2020-07-08T08:36:44Z&spr=https,http&sig=5Qp8WYSZcpHmFOXoNsHec8%2FMj46wMKCOcM6BClv4sT4%3D'
}
