<template>
  <div>
    <div>
      <el-input v-model="inputValue" class="input-short-2" placeholder="请输入内容">
        <template slot="append">
          <el-button @click="onAdd">添加</el-button>
        </template>
      </el-input>
    </div>

    <draggable v-model="value2" class="list-group" group="people" @change="onChange" @end="drag=false"
               @start="drag=true">
      <div v-for="(e, i) in value2" :key="e" class="item">
        <span class="badge">{{i + 1}}</span>
        <span class="content">{{e}} </span>
        <i class="i-del el-icon-close" title="删除" @click="() => onRemove(o)"></i></div>
    </draggable>

    <!--    <ul class="list">-->
    <!--      <li v-for="o in value" :key="o">{{ o
    }} <i title="删除" @click="() => onRemove(o)" class="i-del el-icon-close"></i>-->
    <!--      </li>-->
    <!--    </ul>-->
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  props: {
    value: Array
  },
  components: {
    draggable
  },
  model: {
    prop: 'value',
    event: 'change'
  },

  data() {
    return {
      inputVisible: false,
      inputValue: '',
      value2: ['1asdf', '2sdfg', '3sdfg']
    }
  },
  mounted() {
    this.value2 = this.value || []
  },

  watch: {
    value(v) {
      this.value2 = this.value
    },
  },
  computed: {},
  methods: {
    onChange() {
      this.$emit('change', this.value2)
    },
    onAdd() {
      if (this.inputValue && !this.value.includes(this.inputValue)) {
        this.value2 = [...this.value, this.inputValue]
        this.onChange()
      }
      this.inputValue = ''
    },
    onRemove(s) {
      this.value2 = this.value2.filter(a => a !== s)
      this.onChange()
    },
  }
}
</script>

<style lang="scss" scoped>
.list-group {
  padding: 5px;
  padding-inline-start: 40px;

  .badge {
    margin-right: 10px;
    /*display: inline-block;*/
    /*min-width: 10px;*/
    padding: 3px 7px;
    font-size: 12px;
    font-weight: bold;
    /*line-height: 1;*/
    color: #fff;
    text-align: center;
    /*white-space: nowrap;*/
    /*vertical-align: middle;*/
    background-color: #777;
    border-radius: 15px;
  }

  .item {
    max-width: 300px;
    position: relative;
    display: block;
    padding: 0 15px;
    margin-bottom: -1px;
    background-color: #fff;
    /*border: 1px solid #ddd;*/

  }

  .content {
    cursor: move;

  }

  .i-del {
    margin-left: 10px;
  }
}

/*.list {*/
/*  li {*/
/*    line-height: 32px;*/
/*    font-size: 15px;*/
/*    color: #606266;*/
/*    height: 34px;*/
/*    box-sizing: border-box;*/
/*    cursor: pointer;*/
/*  }*/

/*  .i-del {*/
/*    margin-left: 10px;*/
/*  }*/
/*}*/
</style>

