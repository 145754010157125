<template>
  <el-button type="text"  
             v-clipboard='url' v-clipboard:success='clipboardSuccess'
             >复制</el-button>
</template>

<script>
  import clipboard from '@/core/directives/clipboard' // use clipboard by v-directive

  export default {
    props: ['url'],
    directives: {
      clipboard
    },
    methods: {
      clipboardSuccess(e) {
        this.$message.success('复制成功 ' + this.url)
      }
    }
  }
</script>
