<template>
  <span class="tags">

    <el-tag v-for="t in tags" :key="t"
            :type="t === '待发货' ? 'danger' : 'success'"
            disable-transitions>
            {{ t }}
          </el-tag>
  </span>
</template>

<script>
  export default {
    props: [
      'tags'
    ],
    data() {
      return {}
    },
    mounted() {

    },
    computed: {},
    methods: {}
  }
</script>


