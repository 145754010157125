<template>
  <el-form ref="form" :model="form" label-width="100px">
    <el-form-item v-for="item in schema" :key="item.key" :label="item.name">
      <el-input v-if="item.type === 'str'" v-model="form[item.key]"/>
      <el-input-number v-if="item.type === 'int'" v-model="form[item.key]"/>
      <el-input v-if="item.type === 'float'" v-model="form[item.key]"/>

      <el-radio-group v-if="item.type === 'enum-radio'" v-model="form[item.key]">
        <el-radio v-for="c in item.choices" :key="c" :label="c">{{ c }}</el-radio>
      </el-radio-group>

      <el-select v-if="item.type === 'enum-select'" v-model="form[item.key]" placeholder="请选择">
        <el-option v-for="c in item.choices" :key="c" :label="c" :value="c"/>
      </el-select>

      <el-date-picker v-if="item.type === 'date-range'" v-model="form[item.key]"
                      end-placeholder="结束日期"
                      range-separator="至"
                      start-placeholder="开始日期"
                      type="daterange"/>


      <div v-if="item.tip" class="tip">{{ item.tip }}</div>


      <!--      todo 增加自定义-->
    </el-form-item>
    <!--    <el-form-item label="活动区域">-->
    <!--      <el-select v-model="form.region" placeholder="请选择活动区域">-->
    <!--        <el-option label="区域一" value="shanghai"></el-option>-->
    <!--        <el-option label="区域二" value="beijing"></el-option>-->
    <!--      </el-select>-->
    <!--    </el-form-item>-->
    <!--    <el-form-item label="活动时间">-->
    <!--      <el-col :span="11">-->
    <!--        <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>-->
    <!--      </el-col>-->
    <!--      <el-col class="line" :span="2">-</el-col>-->
    <!--      <el-col :span="11">-->
    <!--        <el-time-picker placeholder="选择时间" v-model="form.date2" style="width: 100%;"></el-time-picker>-->
    <!--      </el-col>-->
    <!--    </el-form-item>-->
    <!--    <el-form-item label="即时配送">-->
    <!--      <el-switch v-model="form.delivery"></el-switch>-->
    <!--    </el-form-item>-->
    <!--    <el-form-item label="活动性质">-->
    <!--      <el-checkbox-group v-model="form.type">-->
    <!--        <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>-->
    <!--        <el-checkbox label="地推活动" name="type"></el-checkbox>-->
    <!--        <el-checkbox label="线下主题活动" name="type"></el-checkbox>-->
    <!--        <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>-->
    <!--      </el-checkbox-group>-->
    <!--    </el-form-item>-->
    <!--    <el-form-item label="特殊资源">-->
    <!--      <el-radio-group v-model="form.resource">-->
    <!--        <el-radio label="线上品牌商赞助"></el-radio>-->
    <!--        <el-radio label="线下场地免费"></el-radio>-->
    <!--      </el-radio-group>-->
    <!--    </el-form-item>-->
    <!--    <el-form-item label="活动形式">-->
    <!--      <el-input type="textarea" v-model="form.desc"></el-input>-->
    <!--    </el-form-item>-->
    <el-form-item>
      <el-button type="primary" @click="onSubmit">立即创建</el-button>
      <el-button>取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>

export default {
  props: [
    // 'form',
    'labels', 'formater', 'size'],
  directives: {},
  computed: {
    _schema() {

      let m = {}
      this.schema.map(r => m[r.key] = r)
      return m

    }
  },
  data() {
    return {
      form: {
        key1: 1,
        key2: '2',
        key3: ['2012-12-12', '2013-12-12'],
        key4: '',
        key5: '2012-12-12',
        key6: '2012-12-12 03:03:03',
        key7: 'some',
      },
      schema: [
        {name: '测试1', key: 'key1', type: 'int', max: 10, min: 1, required: true, tip: '这是一个提示'},
        {name: '测试2', key: 'key2', type: 'str', max: 10, min: 1, required: true},
        {name: '测试3', key: 'key3', type: 'date-range', max: 10, min: 1, required: true},
        {name: '测试4', key: 'key4', type: 'image', max: 10, min: 1, required: true},
        {name: '测试5', key: 'key5', type: 'date', max: 10, min: 1, required: true},
        {name: '测试6', key: 'key6', type: 'datetime', max: 10, min: 1, required: true},
        {name: '测试7', key: 'key7', type: 'enum-select', choices: ['day', 'some', 'one'], required: true},

        {name: '测试10', key: 'key10', type: 'image', required: true},
        {name: '测试11', key: 'key11', type: 'image-list', max: 10, min: 1, required: true},
        {name: '测试12', key: 'key12', type: 'file-list', max: 10, min: 1, required: true},
        {name: '测试13', key: 'key13', type: 'slides', errmsg: ''},

        {name: '测试14', key: 'key14', type: 'tags', errmsg: ''},

      ]
    }
  },
  methods: {
    onSubmit() {

    }
  }
}
</script>

<style lang="scss">
.form-read-only {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  .form-item {
    font-size: 14px;
    vertical-align: middle;
    display: inline-flex;
    color: #606266;
    line-height: 32px;
    padding: 0 12px 0 0;
    box-sizing: border-box;

    .label {
      width: 110px;
      text-align: right;
      font-weight: 500;
    }

    .value {
      margin-left: 20px;
    }

    .tip {
      font-size: 12px;

    }
  }
}
</style>
