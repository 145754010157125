<template>
  <div class="form-image-uploader">
    <el-upload class="_uploader"
               :action="$settings.urls.upload"
               list-type="picture-card"
               :file-list="files"
               :on-success="onSuccess"
               :on-preview="onPreview"
               :on-remove="onRemove">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" style="min-height: 200px">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      value: Array
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    computed: {
      files() {
        return (this.value || []).map(r => {
          return {
            url: r
          }
        })
      }
    },
    watch:{
      value(v){
        // console.log(v)
        // this.files = v || []
      }
    },
    data() {
      return {
        dialogImageUrl: '',
        dialogVisible: false,
        // files: [],
      }
    },
    mounted() {
      if (!this.value) {
        this.$emit('change', [])
      } else {
        // this.files = this.value || []
      }
    },
    methods: {
      onSuccess(resp) {
        console.log(22, 'upload img', resp)
        this.$emit('change', [...this.value, resp])
      },
      onRemove(file, fileList) {
        // console.log(file, fileList)rsync -avzP pnh-serv:/data/envatoscrapy ./envatoscrapy
        this.$emit('change', fileList.map(r => r.url))
        // console.log(33, file, fileList)
      },
      onPreview(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  ._uploader {

  }

</style>

<style lang="scss">
  .form-image-uploader {

    .el-upload-list__item-thumbnail {

    }
    /*.el-upload-list__item {*/
    /*max-width: 260px !important;*/
    /*height: 100% !important;*/
    /*}*/
  }
</style>
