import {DataStore} from './data-store'
import * as filters from './utils/filters'

import ElementUI from "element-ui";

import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import 'element-ui/lib/theme-chalk/index.css'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import './components'
import {clipboard} from "./utils/helper"
import {cookies} from './utils/storage'

import './utils/error'

import './styles/animate.css'
import './styles/index.scss' // global css


export const Core = {
  $data: {},
  install(Vue, options = {}) {
    Vue.use(DataStore, {
      initData: {
        ...options.initData,
      },
      options
    })

    Vue.use(ElementUI, {
      size: options.defaultUISize,
    })

    Object.keys(filters).forEach(key => {
      Vue.filter(key, filters[key])
    })

    Vue.prototype.$settings = options
    Vue.prototype.$clipboard = clipboard
    Vue.prototype.$cookies = cookies
  }
}

