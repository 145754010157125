/*
数据存储
 */
import Cookies from 'js-cookie'


export const storage = {
  get(key, defaultValue) {
    let o = localStorage.getItem(key)
    return o === null ? defaultValue : JSON.parse(o)
  },
  save(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  append(key, value) {
    let o = JSON.parse(localStorage.getItem(key) || '[]')
    o.push(value)
    this.save(key, o)
  },
  remove(key) {
    localStorage.removeItem(key)
  },
  clear() {
    localStorage.clear()
  },
}

export const cookies = {
  set(k, v) {
    Cookies.set(k, v)
  },
  get(k) {
    return Cookies.get(k)
  },
  remove(k) {
    Cookies.remove(k)
  },
  // clean() {
  //   Cookies.clean()
  // }
}
