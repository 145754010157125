import {request} from "../core/utils/request"
import {cookies, storage} from "../core/utils/storage"

export function login({username, password}) {
  return request('/user/login_with_username', {username, password})
}

export function logout() {
  storage.clear()
  cookies.remove('Authorization')
  cookies.remove('UID')
}

export function getInfo({uid}) {
  return request('/user/get', {id: uid})
}
