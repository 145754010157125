<template>
  <div class="uploader">
    <el-upload class="uploader-files" ref="uploader
"
               :action="action"
               :on-success="onSuccess"
               :on-preview="openPreview"
               :on-remove="onRemove"
               multiple
               drag
               :on-exceed="handleExceed"
               :file-list="files">
      <i class="el-icon-upload"></i>
      <!--      <el-button size="small" type="primary">点击上传</el-button>-->
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div v-if="tip" slot="tip" class="el-upload__tip">{{ tip }}</div>
    </el-upload>
  </div>
</template>

<script>
  export default {
    props: {
      value: Array,
      action: String,
      tip: String,
      multi: {
        type: Boolean,
        default: false
      },
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    computed: {},
    data() {
      return {
        files: [],
        showPreviewVideo: false,
        previewVideoUrl: ''
      }
    },

    mounted() {
      this.files = this.value || []
    },
    watch: {
      value(v) {
        this.files = v || []
      }
    },
    methods: {
      onSuccess(url, file) {
        console.log(url, file)
        this.$emit('change', [...(this.value || []), {name: file.name, url}])
      },
      onRemove({url}) {
        if (confirm(`确定移除该文件？`)) {
          this.$emit('change', this.value.filter(f => f.url !== url))
        }
      },
      handleExceed(files, fileList) {
        this.$message.error(`上传发生错误`)
      },
      openPreview({url, response}) {
        window.open(url || response)
      }
    }
  }
</script>

<style lang="scss" scoped>
  ._uploader {

  }

</style>
