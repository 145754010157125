<template>
  <div class="content-wrapper">
    <app-main/>
  </div>
</template>

<script>
import {AppMain, Navbar, Settings, Sidebar} from './components/index'
import settings from '../../settings'

export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain,
    Settings
  },
  data() {
    return {
      fixedHeader: settings.fixedHeader,
    }
  },
  computed: {
    classObj() {
      return {
        // hideSidebar: !this.sidebar.opened,
        // openSidebar: this.sidebar.opened,
        // withoutAnimation: this.sidebar.withoutAnimation,
        // mobile: this.device === 'mobile'
      }
    }
  },
  methods: {}
}
</script>


