import Vue from "vue"

Vue.config.errorHandler = function (err, vm, info, a) {
  // Don't ask me why I use Vue.nextTick, it just a hack.
  // detail see https://forum.vuejs.org/t/dispatch-in-vue-config-errorhandler-has-some-problem/23500
  Vue.nextTick(() => {
    console.log('发生错误',
      err, vm, info, window.location.href)
  })
}


// import Vue from 'vue'
// // import store from '@/store'
// import {isString, isArray} from '@/core/utils/validate'
// import settings from '@/settings'
//
// // you can set in settings.js
// // errorLog:'production' | ['production','development']
// const {errorLog: needErrorLog} = settings
//
// function checkNeed(arg) {
//   const env = process.env.NODE_ENV
//   if (isString(needErrorLog)) {
//     return env === needErrorLog
//   }
//   if (isArray(needErrorLog)) {
//     return needErrorLog.includes(env)
//   }
//   return false
// }
//
// if (checkNeed()) {
//   Vue.config.errorHandler = function (err, vm, info, a) {
//     // Don't ask me why I use Vue.nextTick, it just a hack.
//     // detail see https://forum.vuejs.org/t/dispatch-in-vue-config-errorhandler-has-some-problem/23500
//     Vue.nextTick(() => {
//       // store.dispatch('errorLog/addErrorLog', {
//       //   err,
//       //   vm,
//       //   info,
//       //   url: window.location.href
//       // })
//       // console.error(err, info)
//     })
//   }
// }
