<template>
  <div class="drawer-container">
    <div>
      <h3 class="drawer-title">系统布局配置</h3>

      <div class="drawer-item">
        <span>{{ $t('settings.tagsView') }}</span>
        <el-switch v-model="tagsView" class="drawer-switch"/>
      </div>

      <div class="drawer-item">
        <span>{{ $t('settings.fixedHeader') }}</span>
        <el-switch v-model="fixedHeader" class="drawer-switch"/>
      </div>


    </div>
  </div>
</template>

<script>
import settings from '@/settings'

export default {
  components: {},
  data() {
    return {
      fixedHeader: settings.fixedHeader
    }
  },
  computed: {
    tagsView: {
      get() {
        // return this.$store.state.settings.tagsView
      },
      set(val) {
        // this.$store.dispatch('settings/changeSetting', {
        //   key: 'tagsView',
        //   value: val
        // })
      }
    },
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.drawer-container {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;

  .drawer-title {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 22px;
  }

  .drawer-item {
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    padding: 12px 0;
  }

  .drawer-switch {
    float: right
  }
}
</style>
