import Vue from 'vue'

import CellTags from './cell-tags'
import TagsInput from './tags-input'
import InputCoverImage from './uploader/input-cover-image'
import InputImageMulti from './uploader/input-image-multi'
import InputImageSingle from './uploader/input-image-single'
import TextEditor from './text-editor/index'
import UploaderFiles from './uploader/uploader-files'
import InputSlides from './uploader/input-slides'

import FormImmutable from './form/form-immutable'
import FormAuto from './form/form-auto'
import InputEnums from './form/input-enums'
import TableSearchSimple from './table-tools/table-search-simple'


import CopyLink from './copy-link'

// 注意 有些组件使用异步加载会有影响
Vue.component('cell-tags', CellTags)
Vue.component('tags-input', TagsInput)
Vue.component('input-cover-image', InputCoverImage)
Vue.component('input-image-multi', InputImageMulti)
Vue.component('input-image-single', InputImageSingle)
Vue.component('text-editor', TextEditor)
Vue.component('uploader-files', UploaderFiles)
Vue.component('copy-link', CopyLink)
Vue.component('input-enums', InputEnums)
Vue.component('form-immutable', FormImmutable)
Vue.component('form-auto', FormAuto)
Vue.component('input-slides', InputSlides)
Vue.component('table-search-simple', TableSearchSimple)

