<template>
  <div :class="{'has-logo':showLogo}">
    <!-- todo 添加 Logo Comp-->
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <!--        :default-active="$route.path"-->
      <!--        :collapse="isCollapse"-->
      <!--        :background-color="variables.menuBg"-->
      <!--        :text-color="variables.menuText"-->
      <!--        :active-text-color="variables.menuActiveText"-->
      <!--        :collapse-transition="false"-->
      <!--        mode="vertical"-->
      <!--      <el-menu-->
      <!--              :default-active="$route.path"-->
      <!--        :collapse="isCollapse"-->
      <!--        :background-color="variables.menuBg"-->
      <!--        :text-color="variables.menuText"-->
      <!--        :active-text-color="variables.menuActiveText"-->
      <!--        :collapse-transition="false"-->
      <!--        mode="vertical"-->
      <!--      default-active="2"-->
      <!--      class="el-menu-vertical-demo">-->
      <!--        :background-color="variables.menuBg"-->
      <!--        :text-color="variables.menuText"-->
      <!--        :active-text-color="variables.menuActiveText"-->
      <el-menu
          :active-text-color="variables.menuActiveText"
          :background-color="variables.menuBg"
          :collapse="isCollapse"
          :collapse-transition="false"
          :default-active="$route.path"
          :router="true"
          :text-color="variables.menuText"
          class="el-menu-vertical-demo"
          mode="vertical">
        <div v-for="route in routes" v-if="is_show(route)" :key="route.name">
          <el-menu-item v-if="!route.children" :index="route.path">
            <i class="el-icon-menu"></i>
            {{ route.meta.title }}
          </el-menu-item>

          <el-submenu v-else :index="route.path">
            <template slot="title">
              <i class="el-icon-menu"></i>
              {{ route.meta.title }}
            </template>
            <el-menu-item v-for="r in route.children" v-if="is_show(r)" :key="r.name">
              <el-menu-item :index="r.path" style="padding-left:0">
                <i class="el-icon-menu"></i>
                {{r.meta.title}}
              </el-menu-item>
            </el-menu-item>
          </el-submenu>
        </div>
      </el-menu>
      <!--      <el-menu-->
      <!--        :default-active="$route.path"-->
      <!--        :collapse="isCollapse"-->
      <!--        :background-color="variables.menuBg"-->
      <!--        :text-color="variables.menuText"-->
      <!--        :active-text-color="variables.menuActiveText"-->
      <!--        :collapse-transition="false"-->
      <!--        mode="vertical">-->
      <!--        <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path"/>-->
      <!--      </el-menu>-->
    </el-scrollbar>
  </div>
</template>

<script>
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '../../../styles/variables.scss'

export default {
  components: {SidebarItem, Logo},
  computed: {
    showLogo() {
      return this.$settings.features.showSidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return false
    },
    routes() {
      console.log(this.$router.options.routes[0])
      return this.$router.options.routes[0].children
      const {role} = this.$dbus.account || {}

      return this.$router.options.routes.filter(r => r.children).map(r => {
        console.log(r)
        return {
          ...r,
          children: r.children
              .filter(r => !r.hidden)
              .filter(r => r.meta.allow ? r.meta.allow.includes(role) : true)
        }
      })
    },
  },
  mounted() {

  },
  methods: {
    is_show(r) {
      const me = this.$dbus.account
      if (r.meta.hidden) {
        return false
      }
      if (r.meta.allow && !r.meta.allow.includes(me.role)) {
        return false
      }
      return true
    }
  }
}
</script>
