<template>
  <div class="form-image-uploader single">
    <el-upload
      class="cover-uploader"
      :action="$settings.urls.upload"
      :show-file-list="false"
      :on-success="onSuccess">
      <img v-if="value" :src="value" class="cover">
      <el-button v-else size="small">点击上传</el-button>
<!--      <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
    </el-upload>

    <!--    <el-upload class="_uploader"-->
    <!--               :action="$settings.host + '/upload'"-->
    <!--               list-type="picture-card"-->
    <!--               :limit="1"-->
    <!--               :file-list="files"-->
    <!--               :on-success="onSuccess"-->
    <!--               :on-preview="onPreview"-->
    <!--               :on-remove="onRemove">-->
    <!--      <i class="el-icon-plus"></i>-->
    <!--    </el-upload>-->
    <!--    <el-dialog :visible.sync="dialogVisible">-->
    <!--      <img width="100%" style="min-height: 200px" :src="dialogImageUrl" alt="">-->
    <!--    </el-dialog>-->
  </div>
</template>

<script>
  export default {
    props: {
      value: String
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    computed: {
      // files() {
      //   if (!this.value) {
      //     return []
      //   }
      //   return [{
      //     name: 'image', url: this.value[0] === 'h' ? this.value : this.$settings.urls.base + this.value
      //   }]
      // }
    },
    data() {
      return {
        dialogImageUrl: '',
        dialogVisible: false
      }
    },
    mounted() {
      if (!this.value) {
        this.$emit('change', '')
      }
    },
    methods: {
      onSuccess(resp) {
        this.$emit('change', resp)
      },
      onRemove(file, fileList) {
        this.$emit('change', '')
      },
      onPreview(file) {
        this.dialogImageUrl = this.value
        this.dialogVisible = true
      }
    }
  }
</script>

<style lang="scss">
  .cover-uploader {
    .el-upload {
          text-align: left;

    }
    .cover {
      width: 300px;
    }
  }
  .form-image-uploader.single {
    .el-upload-list__item {
      height: 150px;
      width: 300px;
    }

    .el-upload-list__item-thumbnail {

    }

    /*.el-upload-list__item {*/
    /*max-width: 260px !important;*/
    /*height: 100% !important;*/
    /*}*/
  }
</style>
