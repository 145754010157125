import { render, staticRenderFns } from "./uploader-files.vue?vue&type=template&id=78893a7b&scoped=true&"
import script from "./uploader-files.vue?vue&type=script&lang=js&"
export * from "./uploader-files.vue?vue&type=script&lang=js&"
import style0 from "./uploader-files.vue?vue&type=style&index=0&id=78893a7b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78893a7b",
  null
  
)

export default component.exports