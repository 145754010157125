<template>
  <div class="form-image-uploader">
    <el-upload class="_uploader"
               :action="$settings.urls.upload"
               list-type="picture-card"
               :file-list="files"
               :on-success="onSuccess"
               :on-preview="onPreview"
               :on-remove="onRemove">
      <i class="el-icon-plus"></i>
    </el-upload>
    <!--    <el-dialog title="预览" :visible.sync="dialogVisible" style="min-height: 200px">-->
    <!--      <img width="100%" :src="dialogImageUrl" alt="">-->
    <!--    </el-dialog>-->

    <el-dialog :visible.sync="dialogVisible" width="600px">
      <el-form label-width="80px">
        <el-form-item label="轮播图">
          <img width="100%" :src="dialogForm.url" alt="">
        </el-form-item>
        <el-form-item label="跳转链接">
          <el-input v-model="dialogForm.path"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onUpdate">提交</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    width: Number,
    height: Number,
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  computed: {
    files() {
      if (!this.value) {
        return []
      }
      return this.value.map(r => {
        return {
          name: r.img, url: r.img, path: r.path
        }
      })
    }
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      dialogForm: {},

    }
  },
  mounted() {
    if (!this.value) {
      this.$emit('change', [])
    }
  },
  methods: {
    onSuccess(resp) {
      this.$emit('change', [...this.value, {path: '', img: resp}])
    },
    onRemove(file, fileList) {
      this.$emit('change', this.value.filter(r => r.img !== file.url))
    },
    onUpdate() {
      this.$emit('change', this.files.map(r => {
        return {
          img: r.url,
          path: r.path,
        }
      }))
      this.dialogVisible = false
    },
    onPreview(file) {
      this.dialogVisible = true
      this.dialogForm = file
    }
  }
}
</script>

<style lang="scss" scoped>
._uploader {
  .el-upload-list {
    /*max-width: 800px;*/
  }

}

</style>

<style lang="scss">
.form-image-uploader {

  .el-upload-list__item-thumbnail {

  }

  /*.el-upload-list__item {*/
  /*max-width: 260px !important;*/
  /*height: 100% !important;*/
  /*}*/
}
</style>
