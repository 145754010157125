<template>
  <div class="form-image-uploader">
    <el-upload class="_uploader"
               :action="$settings.urls.upload"
               :on-success="onSuccess"
               :on-preview="onPreview"
               :on-remove="onRemove">
      <img v-if="value" :src="this.$settings.urls.base + value" class="preview">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="this.$settings.urls.base + value" alt="">
    </el-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      value: String
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    computed: {},
    data() {
      return {
        dialogImageUrl: '',
        dialogVisible: false
      }
    },
    mounted() {
      if (!this.value) {
        this.$emit('change', '')
      }
    },
    methods: {
      onSuccess(resp) {
        this.$emit('change', resp)
      },
      onRemove(file, fileList) {
        this.$emit('change', '')
      },
      onPreview(file) {
        // this.dialogImageUrl = this.$settings.urls.base + this.value
        this.dialogVisible = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  ._uploader {

  }

</style>

<style lang="scss">

  .preview {
    width: 180px;
    height: 90px;
  }
</style>
