/*
权限、授权认证
 */

import Cookies from 'js-cookie'

import settings from '@/settings'

export function getToken() {
  return Cookies.get(settings.headerToken)
}

export function set({uid, token}) {
  sessionStorage.setItem(settings.headerToken, token)
  sessionStorage.setItem('uid', uid)
}


export function getUID() {
  return sessionStorage.getItem('uid')
}

export function exit() {
  return sessionStorage.clear()
}

export function checkPermission(value) {
  // if (value && value instanceof Array && value.length > 0) {
  //   const roles = store.getters && store.getters.roles
  //   const permissionRoles = value
  //
  //   const hasPermission = roles.some(role => {
  //     return permissionRoles.includes(role)
  //   })
  //
  //   if (!hasPermission) {
  //     return false
  //   }
  //   return true
  // } else {
  //   console.error(`need roles! Like v-permission="['admin','editor']"`)
  //   return false
  // }
  return true
}

