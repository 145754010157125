import Vue from 'vue'
import App from './App'
import router from './core/router'
import './common.scss' // custom css
import settings from "./settings"
import { APIs } from './apis'

import {Core} from './core'
import * as filters from "./core/utils/filters"

Vue.use(Core, settings)

Vue.config.productionTip = false

Vue.prototype.$api = APIs

import VideoComp from './comps/video-comp'
Vue.component('video-comp', VideoComp)
import Clipboard from 'v-clipboard'
 
Vue.use(Clipboard)

window.appx = new Vue({
  el: '#app',
  router,
  render: h => h(App)
})

