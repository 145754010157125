/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 allow: ['admin','editor']    will control the page roles (you can set multiple roles)
 title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
 icon: 'svg-name'             the icon show in the sidebar
 noCache: true                if true, the page will no be cached(default is false)
 breadcrumb: false            if false, the item will hidden in breadcrumb(default is true)
 affix: true                  if true, the tag will affix in the tags-view
 name 路由名称
 title 显示标题
 path 路径，默认是 '/' + name
 icon: 图标，默认是 guide
 allow: 权限控制 默认是 []
 component: () => import
 hidden: true 是否隐藏

 **/

export default [
  // {
  //   path: '/overview',
  //   component: () => import('./overview'),
  //   name: 'dashboard',
  //   title: '设备概况', icon: 'dashboard', noCache: true, affix: true,
  // },
  // {
  //   path: '/dashboard',
  //   component: () => import('./dashboard'),
  //   name: 'dashboard',
  //   title: '数据总览', icon: 'dashboard', noCache: true, affix: true,
  // },
  // {
  //   name: 'users', title: '用户管理', path: '/users',
  //   component: () => import('./users'),
  //   icon: 'guide', allow: ['admin'],
  // },
  // {
  //   title: 'dev2', name: 'dev1',
  //   component: () => import('./dev'),
  // },
  // {
  //   name: 'alarm', title: '异常报警', path: '/alarm',
  //   component: () => import('./alarm'),
  // },
  // {
  //   name: 'devices', title: '设备列表', path: '/devices',
  //   component: () => import('./devices'),
  // },

  {name: 'dashboard', title: '总览', allow: ['admin'], component: () => import('./dashboard')},
  {name: 'channel-list', title: '星专栏', component: () => import('./channel-list')},
  {name: 'channel-info', title: '专栏详情', component: () => import('./channel-info'), hidden: true},
  {
    name: 'user-list2', 
    title: '用户管理', 
    allow: ['admin'], 
    // component: () => import('./user-list'),
    redirect: '/user-list',
    children:[
      {
        name: 'user-list', 
        title: '用户管理', 
        allow: ['admin'], 
        component: () => import('./user-list'), 
      },{
        name: 'redeem-list',
        title: '兑换券管理',
        allow: ['admin'],
        component: () => import('./redeem-list')
      },
      {name: 'market-list', title: '推广记录', component: () => import('./market-list')},
      {
        name: 'comment2', title: '评论管理', path: '/comment-list2',
        component: () => import('./comment-list'), allow: ['admin'],
      },
    ]
  },
  {name: 'user-info', title: '用户信息', hidden: true, allow: ['admin'], component: () => import('./user-info')},
  {
    name: 'post-list2', 
    title: '帖子', 
    // component: () => import('./post-list')
    redirect: '/post-list', 
    children:[
      { 
        name: 'post-list', 
        title: '帖子发布', 
        component: () => import('./post-list')
      },
      { 
        name: 'message', 
        title: '群发消息', 
        allow: ['admin'],
        component: () => import('./settings-qunfa')
      }
    ]
  },
  {name: 'post-info', title: '帖子编辑', hidden: true, component: () => import('./post-info')},
  {
    name: "goods-list2",
    title: '商品管理', path: '/shop/goods-list',
    // component: () => import('./goods-list'),
    allow: ['admin'],

    redirect: '/shop/goods-list',
    children:[
      {
        name: 'shop/goods-list', 
        title: '商品列表', 
        component: () => import('./goods-list'),
      },
      {
        name: "order-list",
        title: '订单管理', path: '/shop/order-list',
        component: () => import('./order-list'),
        allow: ['admin'],
      },
      {
        name: 'coupon-list',
        title: '优惠券管理',
        allow: ['admin'],
        component: () => import('./coupon-list')
      },
    ]
  },
  {
    name: "goods-editor",
    title: '商品编辑', path: '/shop/goods-editor',
    component: () => import('./goods-editor'),
    allow: ['admin'], hidden: true,
  },
  {
    name: "order-info",
    title: '订单信息', path: '/shop/order-info',
    component: () => import('./order-info'),
    allow: ['admin'], hidden: true,
  },
  { 
      name: 'course-list-index', 
      title: '课程管理', 
      // component: () => import('./course-list'),
      redirect: '/course-list',
      children:[
        {
          name: 'course-list', 
          title: '课程管理', 
          component: () => import('./course-list'),
        },
        {
          name: 'comment', title: '评论管理', path: '/comment-list',
          component: () => import('./comment-list'), allow: ['admin'],
        },
      ]
      // {
      //   path: 'zuzhi',
      //   name: 'jichuzuzhi',
      //   component: () => import('@/views/jichu/zuzhi'),
      //   meta: {
      //     title: '区域管理',
      //   },
      // },
    },
  {name: 'course-editor', title: '课程详情', component: () => import('./course-editor'), hidden: true},
  {name: 'course-chapter-editor', title: '章节详情', component: () => import('./course-chapter-editor'), hidden: true},
  // {
  //   name: 'comment', title: '评论管理', path: '/comment-list',
  //   component: () => import('./comment-list'), allow: ['admin'],
  // },
  {
    name: 'settings', 
    title: '设置',  
    // component: () => import('./settings'),
    redirect: '/settings',
    children:[
      {  path: '/settings',
        name: 'settings', 
        title: '设置',  
        component: () => import('./settings'),
      },
      {name: 'doc', title: '文档', component: () => import('./doc')},
    ]
  },
  {name: 'liveReplay', title: '直播回放', component: () => import('./video-replay-list')},
]
