<template>
  <div id="app">
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    this.$api.request('/config_get').then(data => {
      this.$dbus.config = data
    })

    const uid = this.$dbus.account.id
    if (uid) {
      this.$api.request('/user/get', {id: uid}).then(data => {
        this.$dbus.account = data
      })
    }
  }
}
</script>
