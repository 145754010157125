import Vue from 'vue'
import Router from 'vue-router'
import Layout from './layout'
import Empty from './layout/empty'
import routes from '../views/_routes'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import * as auth from './utils/auth' // getToken from cookie
import settings from '@/settings'

Vue.use(Router)

function convertRoutesTable(routes) {

  let _routes = routes.map(r => {
    if (r.children) {
      return {
        path: r.default || r.children[0].path || `/${r.children[0].name}`,
        component: Empty,
        name: r.name,
        meta: {
          title: r.title, icon: r.icon, noCache: true, affix: true, hidden: r.hidden,
        },
        children: r.children.map(c => {
          return {
            path: c.path || `/${r.name}/${c.name}`,
            component: c.component,
            name: c.name,
            meta: {
              title: c.title,
              icon: (c.icon || 'guide1'),
              noCache: true,
              allow: c.allow,
              hidden: c.hidden,
            }
          }
        })
      }
    } else {
      let path = r.path || '/' + r.name
      return {
        path,
        component: r.component,
        name: r.name,
        meta: {
          title: r.title,
          icon: r.icon || 'dashboard',
          noCache: true,
          allow: r.allow,
          hidden: r.hidden,
        }
      }
    }
  })

  return [
    {
      path: '/',
      component: Layout,
      name: 'root',
      redirect: settings.home,

      children: [
        ..._routes,
      ]
    }
  ]
}

/**
 公共路由
 * */
export const constantRoutes = [
  // /redirect 这个路由应该在路由过程中处理
  {
    path: '/login',
    name: 'login',
    meta: {title: '登陆', noCache: true},
    component: () => import('./pages/login'),
    hidden: true
  },
  {
    path: '/register',
    name: 'register',
    meta: {title: '注册', noCache: true},
    component: () => import('./pages/register'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('./pages/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('./pages/401'),
    hidden: true
  },
  // {
  //   path: '/',
  //   redirect: settings.home
  // },
  {path: '*', redirect: '/404', hidden: true}
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({y: 0}),
  routes: [...convertRoutesTable(routes), ...constantRoutes,]
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}


NProgress.configure({showSpinner: false})// NProgress configuration

const whiteList = ['/login', '/register'] // 不重定向白名单

router.beforeEach((to, from, next) => {
  NProgress.start()

  if (whiteList.includes(to.path)) {
    return next()
  }
  if (!settings.features.forcedLogin) {
    return next()
  }

  if (auth.getToken()) {
    if (to.path === '/login') {
      next({path: '/'})
      NProgress.done()
      // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      next()
      NProgress.done()

    }
  } else {
    next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
    NProgress.done()
  }
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | 管理后台`
  next()
})

router.afterEach(() => {
  NProgress.done() // 结束Progress
})


export default router
