<template>
  <div class="form-read-only">
    <div v-for="(val, key) in form" :key="key" class="form-item">
      <span class="label">{{ labels[key] }}</span>
      <span class="value">{{ formater[key] ? formater[key](val) : val }}</span>
    </div>
  </div>
</template>

<script>

export default {
  props: ['form', 'labels', 'formater', 'size'],
  directives: {},
  data() {
    return {
      // form: {
      //
      // }
    }
  },
  methods: {}
}
</script>

<style lang="scss">
.form-read-only {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  .form-item {
    font-size: 14px;
    vertical-align: middle;
    display: inline-flex;
    color: #606266;
    line-height: 32px;
    padding: 0 12px 0 0;
    box-sizing: border-box;

    .label {
      width: 110px;
      text-align: right;
      font-weight: 500;
    }

    .value {
      margin-left: 20px;
    }

    .tip {
      font-size: 12px;

    }
  }
}
</style>
